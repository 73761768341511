import styled from 'styled-components'

const Title = styled.h1`
  color: ${props => props.theme.colors.navy};
  font-size: ${props => props.theme.type.sizes.desktop.large};
  font-weight: ${props => props.theme.type.weights.normal};
  line-height: 0.95;
  margin: 3.25rem 0;
  padding: 0;
  text-align: center;

  @media (min-width: ${props => props.theme.responsive.medium}) {
    font-size: ${props => props.theme.type.sizes.desktop.huger};
    margin: 4.25rem 0;
  }
  @media (min-width: ${props => props.theme.responsive.large}) {
    font-size: ${props => props.theme.type.sizes.desktop.massive};
    margin: 5.25rem 0;
  }
`
export { Title }
